import React, { useState, useRef, useEffect } from "react";
import LineTo from "react-lineto";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/MarkingPage.css";
import { Undo, Save, RadioButtonChecked, RadioButtonUnchecked, Rotate90DegreesCcw, Done } from "@material-ui/icons";

const MarkingPage = () => {
  const [mapImageLink, setMapImageLink] = useState(null);
  const [mapImageLoaded, setMapImageLoaded] = useState(false);
  const [rotateAngle, setRotateAngle] = useState(0);
  const [mapWallPoints, setMapWallPoints] = useState([]);
  const [mapWallPointsPointer, setMapWallPointsPointer] = useState(0);
  const [addWallPointEnabled, setAddWallPointEnabled] = useState(false);
  const [mapDoorPoints, setMapDoorPoints] = useState([]);
  const [mapDoorPointsPointer, setMapDoorPointsPointer] = useState(0);
  const [addDoorPointEnabled, setAddDoorPointEnabled] = useState(false);
  const mapContainerRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const idParam = params.get("id");

    const imageLink = `http://127.0.0.1:5000/download/${idParam}.png`;

    axios
      .get(imageLink)
      .then((response) => {
        setMapImageLink(imageLink);
        setMapImageLoaded(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("navogate to /not-found");
          navigate("/not-found");
        }
      });
  }, []);

  useEffect(() => {
    const handleMapClick = (event) => {
      if (addWallPointEnabled) {
        setMapWallPoints((prevMapWallPoints) => {
          const updatedMapWallPoints = [...prevMapWallPoints];
          updatedMapWallPoints[mapWallPointsPointer] = [
            ...(updatedMapWallPoints[mapWallPointsPointer] || []),
            { x: event.offsetX, y: event.offsetY },
          ];
          return updatedMapWallPoints;
        });
      } else if (addDoorPointEnabled) {
        setMapDoorPoints((prevMapDoorPoints) => {
          const updatedMapDoorPoints = [...prevMapDoorPoints];
          updatedMapDoorPoints[mapDoorPointsPointer] = [
            ...(updatedMapDoorPoints[mapDoorPointsPointer] || []),
            { x: event.offsetX, y: event.offsetY },
          ];
          return updatedMapDoorPoints;
        });
      }
    };

    const imageClickHandler = (event) => {
      if (event.target.id === "imageMapID") {
        const containerRect = event.target.getBoundingClientRect();
        const clickedPosition = {
          clientX: event.clientX,
          clientY: event.clientY,
          offsetX: event.clientX,
          offsetY: event.clientY - containerRect.top,
        };
        handleMapClick(clickedPosition);
      }
    };
    if (mapImageLoaded) {
      mapContainerRef.current.addEventListener("click", imageClickHandler);
    }

    return () => {
      if (mapImageLoaded) {
        mapContainerRef.current.removeEventListener("click", imageClickHandler);
      }
    };
  }, [addWallPointEnabled, addDoorPointEnabled, mapWallPointsPointer, mapDoorPointsPointer]);

  const handleRotateClick = () => {
    setRotateAngle((prevAngle) => {
      console.log(prevAngle);
      prevAngle = prevAngle + 90;
      if (prevAngle > 360) {
        prevAngle = 90;
      }
      setRotateAngle(prevAngle);
      return prevAngle;
    });
  };

  const handleUndoWallClick = () => {
    setMapWallPoints((prevMapWallPoints) => {
      const tmpMapWallPointsPointer = mapWallPointsPointer;
      const updatedMapWallPoints = [...prevMapWallPoints];
      const lastWallPoints = updatedMapWallPoints[tmpMapWallPointsPointer];
      if (lastWallPoints) {
        updatedMapWallPoints[tmpMapWallPointsPointer] = lastWallPoints.slice(0, -1);
        console.log(lastWallPoints.length);
        if (lastWallPoints.length <= 1) {
          updatedMapWallPoints.splice(tmpMapWallPointsPointer, 1);
          setMapWallPointsPointer(Math.max(tmpMapWallPointsPointer - 1, 0));
        }
      }
      return updatedMapWallPoints;
    });
  };

  const handleUndoDoorClick = () => {
    setMapDoorPoints((prevMapDoorPoints) => {
      const tmpMapDoorPointsPointer = mapDoorPointsPointer;
      const updatedMapDoorPoints = [...prevMapDoorPoints];
      const lastDoorPoints = updatedMapDoorPoints[tmpMapDoorPointsPointer];
      if (lastDoorPoints) {
        updatedMapDoorPoints[tmpMapDoorPointsPointer] = lastDoorPoints.slice(0, -1);
        if (lastDoorPoints.length <= 1) {
          updatedMapDoorPoints.splice(tmpMapDoorPointsPointer, 1);
          setMapDoorPointsPointer(Math.max(tmpMapDoorPointsPointer - 1, 0));
        }
      }
      return updatedMapDoorPoints;
    });
  };

  const handleSendMapPoints = () => {
    console.log("mapWallPoints");
    console.log(mapWallPoints);
    console.log("mapDoorPoints");
    console.log(mapDoorPoints);
    // axios.post('/api/mapWallPoints', { mapWallPoints })
    //   .then(response => {
    //     // Handle success response
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //     // Handle error
    //     console.error(error);
    //   });
  };

  const toggleAddWallPoint = () => {
    setAddDoorPointEnabled(false);
    setAddWallPointEnabled(!addWallPointEnabled);
    console.log(addWallPointEnabled);
    // if (addWallPointEnabled) {
    if (mapWallPoints[mapWallPointsPointer]) setMapWallPointsPointer(mapWallPointsPointer + 1);
    // }
  };

  const toggleAddDoorPoint = () => {
    setAddWallPointEnabled(false);
    setAddDoorPointEnabled(!addDoorPointEnabled);
    console.log(addDoorPointEnabled);
    // if (addDoorPointEnabled) {
    if (mapDoorPoints[mapDoorPointsPointer]) setMapDoorPointsPointer(mapDoorPointsPointer + 1);
    // }
  };

  return (
    <div>
      <div className="pageTitle">
        <h1>Create Parking Map</h1>
      </div>
      <div className="toolBar">
        <button onClick={handleRotateClick} className="toggle-button">
          <Rotate90DegreesCcw />
          Rotate
        </button>
        <button onClick={handleUndoWallClick} className="toggle-button">
          <Undo />
          Undo Wall
        </button>
        <button onClick={handleUndoDoorClick} className="toggle-button">
          <Undo />
          Undo Door
        </button>
        <button
          onClick={toggleAddWallPoint}
          className={`toggle-button ${addWallPointEnabled ? "enabled" : "disabled"}`}
        >
          {addWallPointEnabled ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          {addWallPointEnabled ? "Disable Add WallPoint" : "Enable Add WallPoint"}
        </button>
        <button
          onClick={toggleAddDoorPoint}
          className={`toggle-button ${addDoorPointEnabled ? "enabled" : "disabled"}`}
        >
          {addDoorPointEnabled ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          {addDoorPointEnabled ? "Disable Add DoorPoint" : "Enable Add DoorPoint"}
        </button>
        <button onClick={handleSendMapPoints} className="toggle-button">
          <Done />
          Done
        </button>
      </div>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: imageHeight,
          // width: imageWidth,
        }}
      >
        {mapImageLink != null && (
          <div>
            <img
              id="imageMapID"
              src={mapImageLink}
              alt="Parking Map"
              ref={mapContainerRef}
              style={{
                width: "70vw",
                minWidth: "330px",
                transform: "rotate(" + rotateAngle.toString() + "deg)",
              }}
            />
            {/* Render wall dots */}
            {mapWallPoints.map(
              (wallPoints, pointerIndex) =>
                // <React.Fragment key={pointerIndex}>
                wallPoints.map((marker, index) => (
                  <React.Fragment key={index}>
                    <div
                      id={`walldot-${pointerIndex}-${index}`}
                      className={`walldot-${pointerIndex}-${index}`}
                      style={{
                        position: "absolute",
                        top: marker.y,
                        left: marker.x,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    />
                    {index > 0 && (
                      <LineTo
                        from={`walldot-${pointerIndex}-${index - 1}`}
                        to={`walldot-${pointerIndex}-${index}`}
                        delay={0}
                        borderColor="black"
                        borderWidth={2}
                        borderStyle="solid"
                      />
                    )}
                  </React.Fragment>
                ))
              // </React.Fragment>
            )}

            {/* Render door dots */}
            {mapDoorPoints.map((doorPoints, pointerIndex) => (
              <React.Fragment key={pointerIndex}>
                {doorPoints.map((marker, index) => (
                  <React.Fragment key={index}>
                    <div
                      id={`doordot-${pointerIndex}-${index}`}
                      className={`doordot-${pointerIndex}-${index}`}
                      style={{
                        position: "absolute",
                        top: marker.y,
                        left: marker.x,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "blue",
                        borderRadius: "50%",
                      }}
                    />
                    {index > 0 && (
                      <LineTo
                        from={`doordot-${pointerIndex}-${index - 1}`}
                        to={`doordot-${pointerIndex}-${index}`}
                        delay={0}
                        borderColor="grey"
                        borderWidth={2}
                        borderStyle="dashed"
                      />
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarkingPage;
