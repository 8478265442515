import React, { useState } from "react";
import "../css/UploadPage.css";
import { AttachFile, CloudUpload } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const UploadPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await fetch("http://127.0.0.1:5000/upload", {
        method: "POST",
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      });

      const data = await response.json();
      console.log(data);
      console.log(data.image_links.length);

      if (data.image_links.length > 0) {
        const fillImageLink = getImageFileName(data.image_links[0]);

        console.log(`navogate to /marking?id=${fillImageLink}`);
        navigate(`/marking?id=${fillImageLink}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  function getImageFileName(url) {
    const regex = /\/download\/(\w+)\.png/i;
    const match = url.match(regex);

    if (match && match.length > 1) {
      return match[1];
    }

    return null;
  }

  return (
    <div>
      <div className="pageTitle">
        <h1>Upload Page</h1>
      </div>
      {!selectedFile && (
        <div className="chooseFileBtn">
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="upload-file"
          />
          <label htmlFor="upload-file" className="choose-file-button">
            <AttachFile />
            Choose File
          </label>
        </div>
      )}
      {selectedFile && (
        <div className="fileTitle">
          <label htmlFor="upload-file" className="chosen-file-title">
            <AttachFile />
            {selectedFile.name}
          </label>
        </div>
      )}
      {selectedFile && (
        <div className="uploadBtn">
          <button className="file-upload-button" onClick={handleUpload} disabled={loading}>
            <CloudUpload />
            {loading ? "Uploading..." : "Upload File"}
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadPage;
