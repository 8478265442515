import { Routes, Route } from "react-router-dom";
import UploadPage from "./pages/UploadPage";
import HomePage from "./pages/HomePage";
import MarkingPage from "./pages/MarkingPage";
import NotFoundPage from "./pages/NotFoundPage";

export default function App() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />}></Route>
      <Route exact path="/upload" element={<UploadPage />}></Route>
      <Route exact path="/marking" element={<MarkingPage />}></Route>
      <Route exact path="/not-found" element={<NotFoundPage />}></Route>
      {/* <Route path="/marking/:id" render={(props) => <MarkingPage {...props} />}/>  */}
    </Routes>
  );
}
