import React, { useState, useRef, useEffect } from "react";
import "../css/NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPageBody">
      <div className="error-message">404</div>
    </div>
  );
};

export default NotFoundPage;
