import React from "react";
import { Link } from "react-router-dom";
import "../css/HomePage.css";

const HomePage = () => {
  return (
    <div className="customBody">
      <div className="left">
        <h1>Welcome to APS App</h1>
        <div className="descriptionDiv">
          <p> To begin, please initiate the file upload process. </p>
          <p> Note that only files in valid formats, specifically PDF, are accepted.</p>
        </div>
        <div className="startBtn" style={{ paddingTop: "5%" }}>
          <Link to="/upload" className="start-btn">
            Start
          </Link>
        </div>
      </div>
      {/* <div className="right">
        <img src="/home_vector.png" alt="Image" />
      </div> */}
    </div>
  );
};

export default HomePage;
